import Vue from 'vue'
import VueRouter from 'vue-router'
import PageLogin from '../components/pages/PageLogin.vue'
import PageUserRegister from '../components/pages/PageUserRegister.vue'
import PageSpasms from '../components/pages/PageSpasms.vue'
import PageDependents from '../components/pages/PageDependents.vue'
import PageDependentRegister from '../components/pages/PageDependentRegister.vue'
import PageDependentEdit from '../components/pages/PageDependentEdit.vue'
import PageDependentShare from '../components/pages/PageDependentShare.vue'
import FormSpasm from '../components/forms/FormSpasm.vue'
import store from '../store'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.state.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.state.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: PageDependents,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/dependents',
    name: 'PageDependents',
    component: PageDependents,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/register-dependent',
    name: 'PageDependentRegister',
    component: PageDependentRegister,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/edit-dependent',
    name: 'PageDependentEdit',
    component: PageDependentEdit,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/share-dependent',
    name: 'PageDependentShare',
    component: PageDependentShare,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/spasms',
    name: 'PageSpasms',
    component: PageSpasms,
    beforeEnter: ifAuthenticated,
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/login',
    name:'Login',
    component: PageLogin,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/register',
    name:'UserRegister',
    component: PageUserRegister
  },
  {
    path: '/spasm-register',
    name: 'SpasmRegister',
    component: FormSpasm,
    beforeEnter: ifAuthenticated,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
