<template>
  <div class="PageDependentRegister">
    <v-toolbar-title>Cadastrar dependente</v-toolbar-title>
    <form-dependent />
  </div>
</template>
<script>
import FormDependent from '../forms/FormDependent.vue'

export default {
  components: { FormDependent },
  name:'PageDependentRegister'
}
</script>
