import BaseApi  from './BaseApi'

class UserService extends BaseApi {
  async login(email, password) {
    const path = '/token/'
    const data = {username: email, password: password}
    return this.doRequest('post', path, data)
  }

  async register({firstName, lastName, email, password, profile}) {
    const path = '/v1/users/'
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      profile: {
        sex: profile.sex,
        profile_type: profile.profileType
      }
    }

    return this.doRequest('post', path, data);
  }

  async getUserData() {
    const path = '/v1/users/'
    const response = await this.axios.get(path)
    if (response.data.length) {
      return response.data[0]
    }
    return {}
  }

  async getUsers(query) {
    let path = '/v1/public/users/'
    if (query) {
      path = `${path}?query=${query}`
    }
    const response = await this.axios.get(path)
    return response.data
  }
}

export default UserService
