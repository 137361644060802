<template>
  <v-card>
    <v-bottom-navigation
      v-model="days"
      grow
      color="primary"
    >
      <v-btn value="7">7 dias</v-btn>
      <v-btn value="15">15 dias</v-btn>
      <v-btn value="30">30 dias</v-btn>
      <v-btn value="4">4 meses</v-btn>
    </v-bottom-navigation>
    <line-chart
      v-if="loaded"
      :height="'200px'"
      :chart-data="graphicData"
      :chart-labels="graphicLabels"
      :class="'CrisisChart'"
    ></line-chart>
  </v-card>
</template>

<script>
import LineChart from '../small/LineChart.vue'
export default {
  components: { LineChart },
  name: 'SpasmsChart',
  props: {
    metrics: { type: Object }
  },
  data: function () {
    return {
      days: 7,
      loaded: false,
      last7Days: [],
      last15Days: [],
      last30Days: [],
      last4Months: [],
      last6Months: [],
      graphicLabels: [],
      graphicData: []
    }
  },
  methods: {
    loadMetricsFor(days) {
      this.loaded = false
      let key = `last${days}Days`
      if (days < 7) {
        key = `last${days}Months`
      }
      const dayMetrics = this[key]

      if (dayMetrics) {
        this.graphicLabels = dayMetrics.map(m => m.date)
        this.graphicData = dayMetrics.map(m => m.quantity)
        this.loaded = true
      }
    },
    parseMetrics(metrics) {
      this.last7Days = metrics.last_7_days
      this.last15Days = metrics.last_15_days
      this.last30Days = metrics.last_30_days
      this.last4Months = metrics.last_4_months
      this.last6Months = metrics.last_6_months
    }
  },
  watch: {
    metrics: {
      handler (newValue) {
        this.parseMetrics(newValue)
        this.loadMetricsFor(7)
      },
      deep: true
    },
    days: function (newValue) {
      this.$parent.loadMetrics().then(() => this.loadMetricsFor(newValue))
    }
  },
  mounted: function () {
    this.parseMetrics(this.metrics)
  }
}
</script>

<style scoped>
  .CrisisChart {
    margin-top: 10px;
  }
</style>
