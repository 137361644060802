let store = {
  debug: process.env.NODE_ENV == 'production' ? false : true,
  state: {
    pageToBack: '',
    showSnackBar: false,
    showAlert: false,
    snackBarMessage: '',
    isAuthenticated: false,
    user: {},
    dependents: [],
    contextsItens: []
  },
  printDebugMessage (message, debugObj = {}) {
    if (this.debug) {
      console.log(message, debugObj)
    }
  },
  setShowSnackBarAction (newValue) {
    this.printDebugMessage('setShowSnackBarAction triggered with', {newValue})
    if (navigator.userAgent.toLowerCase().includes('iphone')) {
      this.state.showAlert = newValue
      setTimeout(() => {this.state.showAlert = !newValue}, 5000)
    } else {
      this.state.showSnackBar = newValue
    }
  },
  setSnackBarMessageAction (newValue) {
    this.printDebugMessage('setSnackBarMessageAction triggered with', {newValue})
    this.state.snackBarMessage = newValue
  },
  clearSnackBarMessageAction () {
    this.printDebugMessage('clearSnackBarMessageAction triggered')
    this.state.snackBarMessage = ''
  },
  setIsAuthenticatedAction (newValue) {
    this.state.isAuthenticated = newValue
  },
  setUserAction (userData) {
    this.state.user = userData
  },
  clearUserAction () {
    this.state.user = {}
  },
  setDependentsAction (dependents) {
    this.state.dependents = dependents
  },
  clearDependentsAction () {
    this.state.dependents = []
  },
  setPageToBack (page) {
    this.state.pageToBack = page
  },
  clearPageToBack () {
    this.state.pageToBack = ''
  },
  addContextAction (value) {
    this.state.contextsItens.push(value)
  }
}

export default store
