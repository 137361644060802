import { UserService } from "../lib/spasms-control"
import store from '../store'

const PAGES = {
  dependents: {id: 0, path: '/dependents', label: 'Dependentes', icon: 'mdi-account-child'},
  spasms: {id: 1, path: '/spasms', label: 'Controle de Crises', icon: 'mdi-checkbox-marked-outline'},
}

function saveToken({access, refresh}) {
  localStorage.setItem('authToken', access)
  localStorage.setItem('refreshToken', refresh)
}

function removeToken() {
  localStorage.removeItem('authToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('user')
  localStorage.removeItem('dependentId')
  localStorage.removeItem('dependent')
  localStorage.removeItem('dependents')
}

function saveUser(data) {
  store.setUserAction(data)
  localStorage.setItem('user', JSON.stringify(data))
}

function getActualPage() {
  return localStorage.getItem('actualPage')
}

function setActualPage(page, vue) {
  vue.$root.$children[0].group = page
  return localStorage.setItem('actualPage', page)
}

function getDependent() {
  const dependent = JSON.parse(localStorage.getItem('dependent'))
  return dependent
}

function setDependent(dependent) {
  localStorage.setItem('dependentId', dependent.id)
  localStorage.setItem('dependent', JSON.stringify(dependent))
}

function saveDependents(dependents) {
  localStorage.setItem('dependents', JSON.stringify(dependents))
}

function getDependents() {
  let dependents = []
  const storedDependents = JSON.parse(localStorage.getItem('dependents'))

  if (storedDependents && storedDependents.length) {
    dependents = storedDependents
  }

  return dependents
}

function getToken() {
  return localStorage.getItem('authToken')
}

async function getUser() {

  let user = {}

  try {
    user = store.state.user

    if (!user || !Object.keys(user).length) {
      user = JSON.parse(localStorage.getItem('user'))
    }

    if (!user || !Object.keys(user).length) {
      const authToken = getToken()
      const userService = new UserService(authToken)
      user = await userService.getUserData()
      saveUser(user)
    }
  } catch (error) {
    console.error(error)
  }

  return user
}

function logout(store, router) {
  removeToken()

  if (store) {
    store.setIsAuthenticatedAction(false)
  }

  if (router) {
    router.push({path: '/login'})
  } else {
    window.location.pathname = '/login'
  }
}

export {
  PAGES,
  setActualPage,
  getActualPage,
  getDependent,
  setDependent,
  getToken,
  getUser,
  saveToken,
  removeToken,
  saveUser,
  saveDependents,
  getDependents,
  logout
}
