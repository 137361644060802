<template>
  <v-card
    class="mx-auto"
    color="#546E7A"
    dark
    max-width="400"
  >
    <v-card-text class="headline font-weight-bold text-center">
      <span class="title font-weight-light">Total de crises hoje</span><br/>
      {{quantity}}
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'SpasmsCounter',
  props: {
    quantity: {
      type: Number,
      default: 0,
      required: true
    }
  }
}
</script>
<style scoped>
  .SpasmsCounter .quantity { font-weight: bold; }
</style>
