<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-text-field
      v-model.trim="email"
      label="Seu e-mail"
      :rules="emailRules"
      required
    />
    <v-text-field
      v-model="password"
      label="Sua senha"
      type="password"
      :rules="passwordRules"
      required
    />
    <v-btn
      color="primary"
      class="mr-4"
      :disabled="disabled"
      @click="doLogin"
    >
      Login
    </v-btn>
    <v-row
      align-content="center"
    >
      <v-progress-circular
        v-if="disabled"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <!-- <v-snackbar v-model="snackbar">{{message}}</v-snackbar> -->
  </v-form>
</template>
<script>
import { UserService } from '../../lib/spasms-control'
import { getUser, saveToken } from '../../utils'
import store from '../../store'

export default {
  name: 'FormLogin',
  data: function () {
    return {
      valid: true,
      disabled: false,
      email: '',
      emailRules: [
        v => !!v || 'E-mail é obrigatório',
        v => /.+@.+\..+/.test(v) || 'E-mail precisa ser válido',
      ],
      password: '',
      passwordRules: [
        v => !!v || 'Senha é obrigatório',
        v => v.length >= 6 || 'Min 6 caracters',
      ],
      message: '',
      snackbar: false
    }
  },
  methods: {
    doLogin: function () {
      let validation = this.$refs.form.validate()

      if (validation) {
        this.disabled = true
        const service = new UserService()
        service.login(this.email, this.password).then(async data => {
          saveToken(data)
          await getUser()
          store.setIsAuthenticatedAction(true)
          this.disabled = false
          this.$router.push({path: '/'})
        }).catch(err => {
          console.error({err})
          store.setSnackBarMessageAction(err.response.data.detail)
          store.setShowSnackBarAction(true)
          this.disabled = false
        })
      }
    }
  }
}
</script>
