<template>
  <div class="RegisterSpams">
    <spasms-add-button ref="addSpasm" @click="registerSpasm"/>
    <br/>
    <v-combobox
      @update:search-input="updateContext"
      v-model="context"
      :items="contexts"
      label="Em poucas palavras. O que aconteceu quando a crise ocorreu?"
      outlined
      dense
      hint="ex: comendo, acordando, assustou-se"
      persistent-hint
      :cache-items="true"
    ></v-combobox>
    <div class="RegisterSpamsMessage text-center">{{message}}</div>
  </div>
</template>
<script>
import SpasmService from '../../lib/spasms-control/SpasmService'
import { getToken } from '../../utils'
import SpasmsAddButton from '../small/SpasmsAddButton.vue'
import store from '../../store'

export default {
  name: 'RegisterSpasm',
  components: { SpasmsAddButton },
  props: {
    dependentId: {
      type: Number,
      required: true
    },
    happenedAt: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      message: '',
      context: '',
      contexts: []
    }
  },
  methods: {
    spasmDateTime: function () {
      let spasmDateTime = new Date()
      if (this.happenedAt) {
        spasmDateTime = new Date(this.happenedAt)
      }
      return spasmDateTime
    },
    updateContext: function (event) {
      this.context = event
      this.$ga.event('userInteraction', 'update', 'btn', 'registerSpasm')
    },
    registerSpasm: async function () {
      this.$ga.event('userInteraction', 'click', 'btn', 'registerSpasm')
      this.$refs.addSpasm.loading = true

      const authToken = getToken()
      const service = new SpasmService(authToken)
      const data = {
        happenedAt: this.spasmDateTime().toJSON(),
        dependentId: this.dependentId,
        context: this.context
      }
      if (this.context) {
        this.contexts.push(this.context)
        store.addContextAction(this.context)
      }
      service.create(data)
      .then(data => {
        this.context = ''
        if (data.id) {
          this.message = 'Espamo registrado: ' + this.spasmDateTime().toLocaleString()
          this.$emit('spasmRegistered', data)
        } else {
          this.message = 'Algo deu errado!'
        }
        this.$refs.addSpasm.loading = false
      })
      .catch(error => {
        this.$emit('spasmRegistered', error)
        this.$refs.addSpasm.loading = false
      })
    },
  },
  created: function () {
    this.contexts = this.$root.$data.sharedState.contextsItens
  }
}
</script>
