<template>
  <div class="PageUserResgiter">
    <v-toolbar-title>Cadastrar Usuário</v-toolbar-title>
    <form-register-user />
  </div>
</template>
<script>
import FormRegisterUser from '../forms/FormRegisterUser.vue'
export default {
  components: { FormRegisterUser },
  name: 'PageUserRegister'
}
</script>>
