<template>
  <div class="FormShareDependent">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <!-- <v-select
        v-model="userId"
        required
        :items="users"
        :label="Usuário"
        item-text="name"
        item-value="id"
      ></v-select> -->
      <v-autocomplete
        v-model="user"
        :items="users"
        :loading="isLoading"
        :search-input.sync="search"
        color="white"
        hide-no-data
        hide-selected
        item-text="fullName"
        item-value="id"
        label="Usuario"
        placeholder="Pesquise pelo nome ou e-mail"
        return-object
      ></v-autocomplete>
      <v-select
        v-model="kinship"
        :items="kinships"
        :label="grauParentesco"
        item-text="name"
        item-value="value"
      ></v-select>
      <v-btn
        color="primary"
        class="mr-4"
        :disabled="disabled"
        block
        @click="share"
      >
        Compartilhar
      </v-btn>
    </v-form>
    <v-row
      align-content="center"
    >
      <v-progress-circular
        v-if="disabled"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
  </div>
</template>

<script>
import { DependentService, KINSHIPS, UserService } from '../../lib/spasms-control'
import store from '../../store'
import { getDependent, getToken, getUser } from '../../utils'
export default {
  name: 'FormShareDependent',
  props: {
    dependent: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      valid: false,
      disabled: false,
      user: null,
      dependentId: null,
      shareBy: null,
      kinship: null,
      kinships: KINSHIPS,
      isLoading: false,
      search: null,
      usersList: []
    }
  },
  computed: {
    grauParentesco () {
      return `Qual o grau de parentesco?`
    },
    users () {
      return this.usersList.map(user => {
        const fullName = `${user.first_name} ${user.last_name} (${user.email})`

        return Object.assign({}, user, { fullName })
      })
    }
  },
  watch: {
    dependent: async function (newValue) {
      this.dependentId = newValue.id
    },
    search (val) {
      const notIncludeParenteses = !val.includes('(') && !val.includes(')')
      if (val && notIncludeParenteses && val.length >= 3) {
        this.isLoading = true
        const authToken = getToken()
        const service = new UserService(authToken)

        service.getUsers(val)
          .then(users => this.usersList = users)
          .catch(err => {
            console.error(err)
          })
          .finally(() => (this.isLoading = false))
      }
      // Items have already been loaded
      // if (this.usersList.length > 0) return
    },
  },
  methods: {
    async share() {
      const authToken = getToken()
      const service = new DependentService(authToken)
      const data = {
        userId: this.user.id,
        dependentId:this.dependentId,
        shareBy: this.shareBy,
        kinship: this.kinship
      }
      service.share(data)
        .then(() => {
          store.setSnackBarMessageAction('Compartilhamento realizado com sucesso!')
          store.setShowSnackBarAction(true)
          store.clearPageToBack()
          this.$router.push({path: '/dependents'})
        })
        .catch(err => {
          console.error(err)
          store.setSnackBarMessageAction(err.response.data.detail)
          store.setShowSnackBarAction(true)
        })
    }
  },
  mounted: async function () {
    const user = await getUser()
    const dependent = getDependent()

    if (dependent) {
      this.dependentId = dependent.id
    }
    this.shareBy = user.id
  }
}
</script>

<style>

</style>
