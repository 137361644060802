<template>
  <div class="PageSpasms">
    <dependent-card v-if="dependentObj" :dependent="dependentObj"/>
    <br/>
    <register-spasm v-if="dependentId" @spasmRegistered="updateCounter" :dependentId="dependentId" />
    <br/>
    <v-btn block color="primary" @click="goToSpasmForm">Cadastrar Crise Manualmente</v-btn>
    <br/>
    <spasms-chart :metrics="metrics" />
    <br/>
    <spasms-counter :quantity="spasmQuantity" />
    <br/>
    <spasm-list :spasms="spasms" @deleteSpasm="removeSpasm" />
    <v-snackbar v-model="snackbar">{{message}}</v-snackbar>
  </div>
</template>
<script>
import SpasmService from '../../lib/spasms-control/SpasmService'
import { getDependent, getToken, PAGES, setActualPage } from '../../utils'
import DependentCard from '../container/DependentCard.vue'
import RegisterSpasm from '../container/RegisterSpasm.vue'
import SpasmsCounter from '../small/SpasmsCounter.vue'
import SpasmList from '../container/SpasmList.vue'
import SpasmsChart from '../container/SpasmsChart.vue'
import store from '../../store'

export default {
  components: { SpasmsCounter, RegisterSpasm, DependentCard, SpasmList, SpasmsChart },
  name: 'PageSpasms',
  data: function () {
    return {
      spasmQuantity: 0,
      dependentId: null,
      dependent: {},
      spasms: [],
      snackbar: false,
      message: '',
      metrics: {},
    }
  },
  computed: {
    dependentObj: function () {
      return getDependent()
    }
  },
  methods: {
    updateCounter: function (event) {
      this.spasms.unshift(event)
      this.spasmQuantity += 1
      this.updateMetrics(event)
    },
    updateMetrics: function () {
      const qtd = this.metrics.last_7_days.length
      if (qtd) {
        this.metrics.last_7_days[qtd-1].quantity += 1
      }
    },
    loadSpasms: async function () {
      const authToken = getToken()
      const service = new SpasmService(authToken)
      const today = new Date()
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)
      service.list({dependentId: this.dependentId, happenedAt: today.toJSON()}).then(respose => {
        this.spasms = respose.data
      }).catch(err => {
        this.message = err.message
        this.snackbar = true
        console.error(err)
      })
    },
    loadDependent: function () {
      const dependent = getDependent()

      if (!dependent) {
        return this.$router.push({path: '/dependents'})
      }

      return dependent
    },
    removeSpasm: function (event) {
      const spasmId = event
      const authToken = getToken()
      const service = new SpasmService(authToken)
      this.spasms = this.spasms.filter(spasm => spasm.id != spasmId)
      this.spasmQuantity -= 1
      service.delete(event).catch(err => {
        console.error(err)
        this.message = err.message
        this.snackbar = true
        this.$ga.event('userInteraction', 'error', 'deleteSpasms', err.message)
      })
      this.$ga.event('userInteraction', 'click', 'btn', 'removeSpasm')
    },
    loadMetrics: function () {
      const authToken = getToken()
      const service = new SpasmService(authToken)
      const today = new Date()
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)
      return service.getMetrics(this.dependentId, today.toJSON()).then(response => {
        this.spasmQuantity = response.data.today
        this.metrics = response.data
      })
    },
    goToSpasmForm: function () {
      setActualPage(undefined, this)
      store.setPageToBack('spasms')
      this.$router.push({path: 'spasm-register'})
    }
  },
  mounted: async function () {
    this.loaded = false
    const authToken = getToken()

    if (!authToken) {
      return this.$router.push({path: 'login'})
    }

    const dependent = this.loadDependent()
    this.dependentId = dependent.id

    this.loadMetrics()
    this.loadSpasms()
    setActualPage(PAGES.spasms.id, this)
  }
}
</script>
