import BaseApi from './BaseApi'

class SpasmService extends BaseApi {
  async list({dependentId, happenedAt}) {
    const params = {dependent_id: dependentId, happened_at: happenedAt}
    return this.axios.get('/v1/spasms/', {params})
  }

  async create({dependentId, happenedAt, context}) {
    const data = {
      dependent: dependentId,
      happened_at: happenedAt,
      context: context
    }

    return this.doRequest('post', '/v1/spasms/', data)
  }

  async update({spasmId, dependentId, happenedAt, context}) {
    const path = `/v1/spasms/${spasmId}/`
    const data = {
      dependent_id: dependentId,
      happened_at: happenedAt,
      context: context
    }

    return this.doRequest('patch', path, data)
  }

  async delete(spasmId) {
    const path = `/v1/spasms/${spasmId}/`

    return this.axios.delete(path)
  }

  async getMetrics(dependentId, since=null) {
    let path = `/v1/dependents/${dependentId}/spasms`
    if (since) {
      path = `${path}?happened_at=${since}`
    }
    return this.axios.get(path)
  }
}

export default SpasmService
