<template>
  <div class="FormSpasm">
    <v-form
      ref="formspasm"
    >
      <v-text-field
        v-model.trim="happenedAt"
        label="Data e Hora"
        required
        type="datetime-local"
      />
      <register-spasm v-if="dependentId" @spasmRegistered="spasmRegistered" :dependentId="dependentId" :happenedAt="happenedAt" />
      <v-snackbar v-model="snackbar">{{message}}</v-snackbar>
    </v-form>
  </div>
</template>

<script>
import { getDependent, getToken } from '../../utils'
import RegisterSpasm from '../container/RegisterSpasm.vue'
export default {
  components: { RegisterSpasm },
  name: 'FormSpasm',
  data: function () {
    return {
      dependentId: null,
      happenedAt: null,
      message: '',
      snackbar: false
    }
  },
  methods: {
    spasmRegistered: function (event) {
      if (event.id) {
        this.message = 'Espamos registrado com sucesso!'
      } else {
        this.message = event.message
      }
      this.snackbar = true
    },
    loadDependent: function () {
      const dependent = getDependent()

      if (!dependent) {
        return this.$router.push({path: '/dependents'})
      }

      return dependent
    },
  },
  mounted: function () {
    const authToken = getToken()

    if (!authToken) {
      return this.$router.push({path: 'login'})
    }

    const dependent = this.loadDependent()
    this.dependentId = dependent.id
  }
}
</script>

<style>

</style>
