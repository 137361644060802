import Vue from 'vue';
import Vuetify from 'vuetify';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.pink.accent3, // #F5005
        secondary: colors.green, // #4CAF
        accent: colors.lightGreen.base, // #8BC3
      },
    },
  },
});
