<template>
  <div class="PageLogin">
    <v-toolbar-title>Fazer Login</v-toolbar-title>
    <form-login />
  </div>
</template>
<script>
import { getDependent } from '../../utils'
import FormLogin from '../forms/FormLogin.vue'

export default {
  name: 'PageLogin',
  components: {
    FormLogin
  },
  methods: {
    async checkSelectedDependent () {
      const dependent = getDependent()
      if (dependent) {
        this.$router.push({path: '/spasms'})
      } else {
        this.$router.push({path: '/'})
      }
      return dependent
    },
    async checkIfLogged() {
      if (this.$root.$data.sharedState.isAuthenticated) {
        await this.checkSelectedDependent()
      } else {
        setTimeout(this.checkIfLogged, 1000);
      }
    }
  },
  mounted: function () {
    this.checkIfLogged();
  }
}
</script>
