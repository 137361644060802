<template>
  <div class="DependentLetter text-align:center">
    <v-avatar
      color="indigo"
      size="56"
    >
      <span class="white--text headline">{{letters}}</span>
    </v-avatar>
    {{dependent.name}}
  </div>
</template>
<script>
export default {
  name: 'DependentCard',
  props: {
    dependent: {
      type: Object,
      required: true
    }
  },
  computed: {
    letters: function() {
      let names = this.dependent.name.split(' ')
      const nameSize = names.length
      const firstLetter = names[0].substring(0, 1)
      const lastName = names[nameSize-1].substring(0, 1)
      return `${firstLetter}${lastName}`
    }
  }
}
</script>
