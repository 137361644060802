<template>
  <v-btn
    @click="wasClicked"
    block
    :loading="loading"
    :disabled="loading"
    color="secondary"
    x-large
  >Marcar Crise</v-btn>
</template>
<script>
export default {
  name: 'SpasmsAddButton',
  // props: ['loading'],
  data: function () {
    return {
      loading: false
    }
  },
  methods: {
    wasClicked: function (event) {
      this.$emit('click', {event})
    }
  }
}
</script>
