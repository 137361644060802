import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import './ganalytics'

import store from './store'

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App),
  data: {
    sharedState: store.state
  }
}).$mount('#app')
