<template>
  <v-card
    class="mx-auto"
    max-width="100%"
  >
    <v-list v-if="hasInvitations">
      <v-subheader v-text="subheaderText"></v-subheader>
      <v-list-item-group v-model="item">
        <v-list-item
          v-for="(invitation, i) in notAcceptedInvitations"
          :key="i"
        >
          <v-list-item-content>
            <v-list-item-title v-text="formatedInvitationTitle(invitation)"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
          <v-icon :color="'grey'" @click="acceptInvitation(invitation)">
            mdi-check
          </v-icon>
        </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { InvitationService } from '../../lib/spasms-control'
import { getToken } from '../../utils'
export default {
  name: 'SharedDependents',
  data: function () {
    return {
      item: null,
      invitations: []
    }
  },
  computed: {
    hasInvitations() {
      return this.invitations.length > 0
    },
    notAcceptedInvitations() {
      return this.invitations.filter(invitation => invitation.accepted_date === null)
    },
    subheaderText() {
      if (this.notAcceptedInvitations.length) {
        return 'Dependentes compartilhados com você - Confirme se aceita o compartilhamento'
      }

      return 'Nenhum dependentes compartilhado'
    }
  },
  methods: {
    formatedInvitationTitle(invitation) {
      return `${invitation.dependent.name} de ${invitation.from_user.first_name} ${invitation.from_user.last_name}`
    },
    acceptInvitation(invitation) {
      const token = getToken()
      if (token) {
        const service = new InvitationService(token)
        service.accept(invitation.id)
        .then(() => {
          this.invitations = this.invitations.filter(invi => invi.id != invitation.id)
          this.$emit('invitationAccepted');
        })
        .catch(err => console.error(err))
      }
    },
    loadSharedDependents() {
      const token = getToken()
      if (token) {
        const service = new InvitationService(token)
        service.list()
        .then(response => {
          this.invitations = response.data
        })
        .catch(err => console.error(err))
      }
    }
  },
  mounted: function () {
    this.loadSharedDependents()
  }
}
</script>

<style>

</style>
