import axios from 'axios'
import store from '../../store'
import { logout } from '../../utils';
// import router from '../../router'

class BaseApi {
  constructor (authToken) {
    this.axios = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      timeout: 30000,
    });

    this.axios.defaults.headers.common['TimezoneOffset'] = this.getTimezoneOffset();

    if (authToken) {
      this.axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    }

    this.axios.interceptors.response.use(undefined, function (err) {
      console.error({err})
      return new Promise(function () { // resolve, reject
        let unAuthCode = false;
        let config = undefined;

        if (err.response) {
          unAuthCode = [401, 403].includes(err.response.status)
        }

        config = err.config

        if (unAuthCode && config && config.__isRetryRequest && config.__isRetryRequest === true) {
          logout(store)
        }
        throw err;
      });
    });
  }

  async doRequest(method, url, data) {
    const response = await this.axios({method, url, data})
    return response.data
  }

  getTimezoneOffset() {
    const offset = new Date().getTimezoneOffset()
    return offset
  }
}

export default BaseApi
