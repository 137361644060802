<template>
  <div class="FormDependent">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-text-field
        v-model.trim="name"
        label="Nome"
        required
      />
      <v-select
        v-model="sex"
        :items="sexItems"
        label="Sexo"
        item-text="label"
        tem-value="value"
      ></v-select>
      <v-text-field
        v-model.trim="birthDate"
        label="Data Nascimento"
        required
        type="date"
      />
      <v-select
        v-model="kinship"
        :items="kinships"
        :label="grauParentesco"
        item-text="name"
        tem-value="value"
      ></v-select>
      <v-btn
        v-if="!id"
        color="primary"
        class="mr-4"
        :disabled="disabled"
        block
        @click="register"
      >
        Cadastrar
      </v-btn>
      <v-btn
        v-else
        color="primary"
        class="mr-4"
        :disabled="disabled"
        block
        @click="update"
      >
        Atualizar
      </v-btn>
    </v-form>
    <v-row
      align-content="center"
    >
      <v-progress-circular
        v-if="disabled"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
  </div>
</template>
<script>
import { DependentService, KINSHIPS } from '../../lib/spasms-control'
import { getToken, getUser } from '../../utils'
import store from '../../store'

export default {
  name: 'FormDependent',
  props: {
    dependent: {
      type: Object,
      required: false
    }
  },
  data: function () {
    return {
      valid: true,
      disabled: false,
      snackbar: false,
      kinships: KINSHIPS,
      id: null,
      name: '',
      sex: '',
      sexItems: [
        {label: 'Masculino', value: 'M'},
        {label: 'Feminino', value: 'F'}
      ],
      birthDate: null,
      kinship: '',
      message: ''
    }
  },
  computed: {
    grauParentesco: function () {
      return `Você é o que de ${this.name ? this.name : 'Dependente'}?`
    }
  },
  watch: {
    dependent: async function (newValue) {
      this.fillDependent(newValue)
    }
  },
  methods: {
    register: async function () {
      this.disabled = true
      const validation = this.$refs.form.validate()
      if (validation) {
        const authToken = getToken()
        const user = await getUser()
        const service = new DependentService(authToken)
        const dependentData = {
          name: this.name,
          sex: this.sex,
          birthDate: this.birthDate,
          kinship: this.kinship,
          userId: user.id,
          shareById: user.id,
        }
        service.create(dependentData).then(data => {
          let dependents = []
          dependents = this.$root.$data.sharedState.dependents

          if (data) {
            dependents.push(data)
            store.setDependentsAction(dependents)
            store.setSnackBarMessageAction(data.name + ' Criado com sucesso!')
            this.name = ''
            this.sex = ''
            this.birthDate = ''
            this.kinship = ''
            store.setShowSnackBarAction(true)
          }
          this.disabled = false
        }).catch(error => {
          store.setSnackBarMessageAction(error.message)
          console.error(error)
          this.disabled = false
          store.setShowSnackBarAction(true)
        })
      }
    },
    update: async function () {
      this.disabled = true
      const validation = this.$refs.form.validate()
      if (validation) {
        const authToken = getToken()
        const user = await getUser()
        const service = new DependentService(authToken)
        const dependentData = {
          id: this.id,
          name: this.name,
          sex: this.sex,
          birthDate: this.birthDate,
          kinship: this.kinship,
          userId: user.id
        }
        service.update(dependentData).then(data => {
          if (data) {
            store.setSnackBarMessageAction(data.name + ' atualizado com sucesso!')
            store.setShowSnackBarAction(true)
          }
          this.disabled = false
        }).catch(error => {
          store.setSnackBarMessageAction(error.message)
          console.error(error)
          this.disabled = false
          store.setShowSnackBarAction(true)
        })
      }
    },
    fillDependent: async function (dependent) {
      if (dependent && dependent.id) {
        const user = await getUser()
        this.id = dependent.id
        this.name = dependent.name
        this.sex = dependent.sex,
        this.birthDate = dependent.birth_date
        const kinship = dependent.kinships.filter(kinship => kinship.user_id == user.id)[0]
        this.kinship = kinship.kinship
      }
    }
  },
  mounted: function () {
    this.fillDependent(this.dependent)
  }
}
</script>
