<script>
  import { Line } from 'vue-chartjs'

  export default {
    extends: Line,
    props: {
      chartData: {
        type: [Array, Object],
        required: false
      },
      chartLabels: {
        type: Array,
        required: true
      },
      height: {
        type: String
      }
    },
    data () {
      return {
        options: {
          scales: {
            yAxes: [{
              ticks: {
                stepSize: 1,
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [ {
              gridLines: {
                display: true
              }
            }]
          },
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    methods: {
      render: function () {
        this.renderChart({
          labels: this.chartLabels,
          datasets: [
            {
              label: 'Crises',
              borderColor: '#249EBF',
              pointBackgroundColor: 'white',
              borderWidth: 1,
              pointBorderColor: '#249EBF',
              backgroundColor: 'transparent',
              data: this.chartData
            }
          ]
        }, this.options)
      }
    },
    watch:{
      chartLabels:function () {
        this.render()
      },
      chartData: function () {
        this.render()
      }
    },
    mounted () {
      this.render()
    }
  }
</script>
