<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon v-if="showNavigationDrwer" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-icon v-text="'mdi-keyboard-backspace'" v-if="showBackButton" @click="backPage"></v-icon>
      <div v-if="showBackButton" class="mx-2"></div>
      <v-toolbar-title>Controle de Crises</v-toolbar-title>

      <v-spacer></v-spacer>
      <router-link to="/register">
        <v-btn
          v-if="showRegisterButton"
          text
        >
          <span class="mr-2">Cadastre-se</span>
        </v-btn>
      </router-link>
      <router-link to="/login">
        <v-btn
          v-if="showLoginButton"
          text
        >
          <span class="mr-2">Login</span>
        </v-btn>
      </router-link>
    </v-app-bar>

    <v-navigation-drawer
      v-if="sharedState.isAuthenticated"
      v-model="drawer"
      fixed
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          ref="drawerGroup"
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item v-for="page in pages" :key="page.id">
            <v-list-item-icon v-if="page.icon">
              <v-icon>{{page.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{page.label}}</v-list-item-title>
          </v-list-item>
          <v-list-item link :href="'mailto:contato@gcodetec.com?subject=[Controle Crises Sugestão]'">
            <v-list-item-icon>
              <v-icon>mdi-account-voice</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sugestões</v-list-item-title>
          </v-list-item>
          <v-list-item link :href="'https://wa.me/5571987886089?text=Olá! Qual a sua dúvida?'">
            <v-list-item-icon>
              <v-icon>mdi-help-box</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Suporte</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
      <v-btn
        color="green"
        dark
        fixed
        bottom
        right
        fab
        :href="'https://wa.me/5571987886089?text=Olá! Qual a sua dúvida?'"
      >
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
    </v-main>
    <v-snackbar absolute v-model="sharedState.showSnackBar">{{sharedState.snackBarMessage}}</v-snackbar>
    <v-alert
      dismissible
      type="success"
      v-model="sharedState.showAlert"
    >{{sharedState.snackBarMessage}}</v-alert>
    <v-footer padless>
      <v-col
        class="text-center"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>GCODETEC</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { getToken, logout, PAGES, setActualPage } from './utils';
import store from './store'

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    group: null,
  }),

  computed: {
    pages: () => {
      return Object.values(PAGES)
    },
    links: () => ( Object.values(PAGES).map((page) => page.path) ),
    showBackButton: function () {
      return this.$root.$data.sharedState.pageToBack ? true : false
    },
    showNavigationDrwer: function () {
      return this.$root.$data.sharedState.isAuthenticated && !this.showBackButton
    },
    showRegisterButton: function () {
      return !this.$root.$data.sharedState.isAuthenticated && this.$route.fullPath == '/login'
    },
    showLoginButton: function () {
      return !this.$root.$data.sharedState.isAuthenticated && this.$route.fullPath == '/register'
    },
    sharedState: function () {
      return this.$root.$data.sharedState
    }
  },
  watch: {
    group (newValue) {
      const path = this.links[newValue]

      if (!path || path === this.$route.fullPath) {
        return
      }

      this.$router.push({path})
      this.$ga.event('userInteraction', 'click', 'menu', path)
      this.drawer = false
    },
  },
  methods: {
    logout: function() {
      logout(store, this.$router)
    },
    checkIsLogged: function() {
      const authToken = getToken()

      if (authToken) {
        store.setIsAuthenticatedAction(true)
      } else {
        store.setIsAuthenticatedAction(false)
        setTimeout(this.checkIsLogged, 1000)
      }
    },
    backPage: function () {
      if (this.$root.$data.sharedState.pageToBack) {
        const pageObj = PAGES[this.$root.$data.sharedState.pageToBack]
        setActualPage(pageObj.id, this)
        store.clearPageToBack()
      }
    }
  },
  mounted: function () {
    setTimeout(this.checkIsLogged, 1000)
  }
};
</script>
