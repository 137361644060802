import BaseApi from './BaseApi'

class DependentService extends BaseApi {
  async list() {
    return this.axios.get('/v1/dependents/')
  }

  async get(dependent_id) {
    const path = `/v1/dependents/${dependent_id}/`
    return this.axios.get(path)
  }

  async create({name, sex, birthDate, kinship, userId, shareById}) {
    const data = {
      name,
      sex,
      birth_date: birthDate,
      kinships: [
        {
          kinship: kinship,
          share_by_id: shareById,
          user_id: userId
        }
      ]
    }

    return this.doRequest('post', '/v1/dependents/', data)
  }

  /**
   * Update a dependent.
   * @param {int} id - The dependent id.
   * @param {string} name - The dependent name.
   * @param {string} sex - The dependent sex.
   * @param {string} birthDate - The dependent birth date.
   * @param {string} kinship - The kinship.
   * @param {int} userId - The kinship.
   */
  async update({id, name, sex, birthDate, kinship, userId}) {
    const data = {
      name,
      sex,
      birth_date: birthDate,
      kinships: [
        {
          kinship: kinship,
          user_id: userId
        }
      ]
    }

    const path = `/v1/dependents/${id}/`
    return this.doRequest('patch', path, data)
  }

  async share({userId, dependentId, shareBy, kinship}) {
    const data = {
      user: userId,
      dependent: dependentId,
      share_by: shareBy,
      kinship: kinship
    }

    const path = `/v1/userdependents/`
    return this.doRequest('post', path, data)
  }
}

export default DependentService
