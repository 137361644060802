import UserService from './UserService'
import SpasmsService from './SpasmService'
import DependentService from './DependentService'
import InvitationService from './InvitationService'

const KINSHIPS = [
  {value: 'father', name: 'Pai'},
  {value: 'mother', name: 'Mãe'},
  {value: 'brother', name: 'Irmão'},
  {value: 'sister', name: 'Irmã'},
  {value: 'grandmother', name: 'Vovó'},
  {value: 'grandfather', name: 'Vovô'},
  {value: 'uncle', name: 'Tio'},
  {value: 'aunt', name: 'Tia'},
  {value: 'son', name: 'Filho'},
  {value: 'daughter', name: 'Filha'},
  {value: 'none', name: 'Nenhum'},
]


export {
  UserService,
  SpasmsService,
  DependentService,
  InvitationService,
  KINSHIPS
}
