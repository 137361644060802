<template>
  <div class="PageDependents">
    <v-btn
      @click="goToDependentRegister"
      block
      color="secondary"
    >Cadastar o dependente</v-btn>
    <br/>
    <v-card
      class="mx-auto"
      max-width="100%"
    >
      <v-list v-if="hasDependents">
        <v-list-item-group v-model="model">
          <v-list-item
            v-for="(dependent, i) in dependents"
            :key="i"
          >
            <v-list-item-icon @click="selectDependent(dependent)">
              <v-icon v-text="'mdi-baby-face-outline'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="selectDependent(dependent)">
              <v-list-item-title v-text="dependent.name"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
            <v-icon :color="'grey'" @click="shareDependent(dependent)">
              mdi-share-variant-outline
            </v-icon>
            <v-icon :color="'grey'" @click="editDependent(dependent)">
              mdi-pencil
            </v-icon>
          </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-list v-else>
        <v-list-item-group v-model="model">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="'Nenhum Dependente'"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon @click="loadFromServer()">
              <v-icon v-text="'mdi-refresh'"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <br/>
    <shared-dependents @invitationAccepted="loadFromServer" />
  </div>
</template>
<script>
  import { DependentService } from '../../lib/spasms-control'
  import { getDependents, PAGES, saveDependents, setActualPage, setDependent } from '../../utils'
  import store from '../../store'
  import SharedDependents from '../container/SharedDependents.vue'

  export default {
    name: 'PageDependents',
    components: {SharedDependents},
    data: function () {
      return {
        dependents: [],
        model: null
      }
    },
    computed: {
      hasDependents: function()  {
        return this.dependents.length > 0
      }
    },
    methods: {
      goToDependentRegister: function () {
        setActualPage(undefined, this)
        store.setPageToBack('dependents')
        this.$router.push({path: '/register-dependent'})
        this.$ga.event('userInteraction', 'click', 'btn', 'registerDependent')
      },
      selectDependent(dependent) {
        setDependent(dependent)
        setActualPage(PAGES.spasms.id, this)
        this.$router.push({path: '/spasms'})
        this.$ga.event('userInteraction', 'click', 'btn', 'selectDependent')
      },
      loadDependentsFromStorage() {
        this.dependents = getDependents()
      },
      loadFromState() {
        this.dependents = this.$root.$data.sharedState.dependents
      },
      loadFromServer() {
        const authToken = localStorage.getItem('authToken');

        if (!authToken) {
          this.$router.push({path: 'login'})
        }

        if (authToken) {
          store.setSnackBarMessageAction('Atualizando lista de dependentes')
          store.setShowSnackBarAction(true)
          const service = new DependentService(authToken);
          service.list().then(response => {
            store.setDependentsAction(response.data)
            saveDependents(response.data)
            this.dependents = response.data
            store.setShowSnackBarAction(false)
          }).catch(err => {
            console.error('loadFromServer error')
            console.error({err})
            store.setSnackBarMessageAction('Erro ao atualiar lista de dependentes')
            store.setShowSnackBarAction(true)
          });
        }
      },
      loadDependents: function () {
        const authToken = localStorage.getItem('authToken');

        if (!authToken) {
          this.$router.push({path: 'login'})
        }

        this.loadFromState()

        if (this.dependents.length == 0) {
          this.loadDependentsFromStorage()
        }

        this.loadFromServer()

        if (this.dependents.length === 0) {
          setTimeout(() => this.loadFromServer(), 1000);
        }
      },
      editDependent: function (dependent) {
        setDependent(dependent)
        setActualPage(undefined, this)
        store.setPageToBack('dependents')
        this.$router.push({path: '/edit-dependent'})
      },
      shareDependent: function (dependent) {
        setDependent(dependent)
        setActualPage(undefined, this)
        store.setPageToBack('dependents')
        this.$router.push({path: '/share-dependent'})
      }
    },
    created: function () {
      this.loadDependents()
      setActualPage(PAGES.dependents.id, this)
    }
  }
</script>
