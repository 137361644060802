<template>
  <div class="PageDependentEdit">
    <v-toolbar-title>Editar {{dependent.name}}</v-toolbar-title>
    <form-dependent :dependent="dependent" />
  </div>
</template>
<script>
import { getDependent } from '../../utils'
import FormDependent from '../forms/FormDependent.vue'

export default {
  components: { FormDependent },
  name:'PageDependentEdit',
  data: function () {
    return {
      dependent: {}
    }
  },
  methods: {
    loadDependent: function() {
      this.dependent = getDependent()
    }
  },
  mounted: function () {
    this.loadDependent()
  }
}
</script>
