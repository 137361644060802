<template>
  <div class="FormRegisterUser">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-text-field
        v-model.trim="firstName"
        label="Primeiro Nome"
        required
      />
      <v-text-field
        v-model.trim="lastName"
        label="Último Nome"
        required
      />
      <v-text-field
        v-model.trim="email"
        label="E-mail"
        type="email"
        required
      />
      <v-text-field
        v-model.trim="password"
        label="Senha"
        :type="showPassword ? 'text' : 'password'"
        :rules="passwordRules"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        required
        counter
        @click:append="showPassword = !showPassword"
      />
      <v-text-field
        v-model.trim="confirmPassword"
        label="Confirmar Senha"
        :type="showPassword ? 'text' : 'password'"
        :rules="[...passwordRules, passwordMatch]"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        required
        counter
        @click:append="showPassword = !showPassword"
      />
      <v-select
        v-model="sex"
        :items="sexItems"
        label="Sexo"
        item-text="label"
        tem-value="value"
      ></v-select>
      <v-btn
        color="primary"
        class="mr-4"
        :disabled="disabled"
        block
        @click="register"
      >
        Cadastre-se
      </v-btn>
    </v-form>
    <v-snackbar v-model="snackbar">{{message}}</v-snackbar>
    <v-row
      align-content="center"
    >
      <v-progress-circular
        v-if="disabled"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
  </div>
</template>

<script>
import { UserService } from '../../lib/spasms-control'
import store from '../../store';
import { getUser, saveToken } from '../../utils';
export default {
  name: 'FormRegisterUser',
  data: function () {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      passwordRules: [
        v => !!v || 'Senha é obrigatório',
        v => v.length >= 6 || 'Min 6 caracters',
      ],
      showPassword: false,
      sex: '',
      sexItems: [
        {label: 'Masculino', value: 'M'},
        {label: 'Feminino', value: 'F'}
      ],
      valid: false,
      snackbar: false,
      disabled: false,
      message: ''
    }
  },
  computed: {
    passwordMatch() {
      return this.password === this.confirmPassword || "A senha deve corresponder";
    }
  },
  methods:{
    register: async function () {
      this.disabled = true
      const service = new UserService()
      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        profile: {
          sex: this.sex,
          profileType: 'normal'
        }
      }
      service.register(data).then(data => {
        if (data.id) {
          service.login(this.email, this.password).then(async data => {
            saveToken(data)
            await getUser()
            store.setIsAuthenticatedAction(true)
            this.$router.push({path: '/'})
          }).catch(err => {
            console.error({err})
            store.setSnackBarMessageAction(err.response.data.detail)
            store.setShowSnackBarAction(true)
          })
        }

      }).catch(err => {
        console.error(err)
        this.disabled = false
        this.message = err.message
        this.snackbar = true
      })
    }
  }
}
</script>

<style>

</style>
