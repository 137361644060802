import BaseApi from './BaseApi'

class InvitationService extends BaseApi {
  async list(filters = {}) {
    const queryString = new URLSearchParams(filters).toString();
    return this.axios.get(`/v1/invitations/?${queryString}`)
  }

  async accept(id) {
    return this.axios.patch(`/v1/invitations/${id}/`)
  }
}

export default InvitationService
