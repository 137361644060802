<template>
  <v-card
    class="mx-auto SpasmListCard"
    max-width="100%"
    tile
  >
    <v-list flat class="SpasmList">
      <v-subheader>CRISES DE HOJE</v-subheader>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
          v-for="(spasm, i) in spasms"
          :key="i"
        >
          <v-list-item-icon @click="deleteSpasm(spasm.id)">
            <v-icon v-text="'mdi-delete'"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="formatDate(spasm.happened_at)"></v-list-item-title>
            <v-list-item-subtitle>{{spasm.context}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
export default {
  name: 'SpasmList',
  props: {
    spasms: {
      type: Array
    }
  },
  data: function () {
    return {
      selectedItem: undefined
    }
  },
  methods: {
    formatDate: function (jsonDate) {
      let date = new Date(jsonDate)
      return date.toLocaleString()
    },
    deleteSpasm: function (id) {
      this.selectedItem = undefined
      this.$emit('deleteSpasm', id)
    }
  }
}
</script>
